import React, { useState, useEffect } from "react";
import axios from "axios";
import Moment from 'react-moment';
import ReactLoading from 'react-loading';
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import TablePagination from '@mui/material/TablePagination';
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel'
import Sub_header from '../Sub_header'
import '../../components/loader.css';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'




const Users = () => {
    let navigate = useNavigate();
    const search1 = window.location.search;
    const params = new URLSearchParams(search1);
    let search = params.get('search')
    const [state, setState] = useState({ isLoading: true, user_list: [] });
    const [otherStates, setOtherState] = useState({ dstatus: "", activePage: 1, rowsPerPage: 500, page: 0, total_count: 0, onload: true });
    const [modalState, setModalState] = useState({ show: false, user_id: "", action: "" });
    const [editState, setEditState] = useState({ postal_id: "", additive: "", postal_code: "", city_id: "", state_id: "", country_id: "" });
    const [newState, setNewState] = useState({ additive: "", postal_code: "" });
    const [searchfilters, setsearchFilters] = useState({ searchtitle: "" });
    const [editModalState, setEditModalState] = useState({ full_name: "", email: "", phone: "", admin_type: "",password:"",shipments:false,customers:false,master_settings:false,system_settings:false,payments:false,users:false,web_management:false,create_shipment:false, show: false });
    console.log("editModalState === " , editModalState)
    const [newtModalState, setNewModalState] = useState({ admin_id:"",full_name: "", email: "", phone: "", admin_type: "",password:"",shipments:false,customers:false,master_settings:false,system_settings:false,payments:false,users:false,web_management:false, create_shipment:false, show: false });
    const [isLoading, setIsLoading] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 5000
    })

    useEffect(() => {
        getUsersList(0, true);
    }, [])

    useEffect(() => {
        // setOtherState({...otherStates,searchKeyword:""}) 
        // getUsersList(0,true);
    }, []);

    const getUsersList = (index = 0, onLoad) => {

        setIsLoading(true)
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/admin_user_list`;
        let sendData = { dstatus: otherStates.dstatus, limit: otherStates.rowsPerPage, indexValue: index };

        if (searchfilters.searchtitle) {
            sendData.searchKeyword = searchfilters.searchtitle
        }
        console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            setIsLoading(false)
            console.log("response ", res);
            console.log("otherStates ", otherStates);
            if (index == 0 && onLoad) {
                setOtherState({ ...otherStates, total_count: res.data.output.length })

            }
            setState({ ...state, user_list: res.data.output, isLoading: false })



        }).catch((e) => {
            setIsLoading(false)

        });
    }

    const usersAction = () => {


        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user_list`;
        let sendData = { dstatus: otherStates.dstatus };
        // console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            // console.log("res ", res);

            setState(res.data.output)



        }).catch((e) => {


        });
    }

   

    const editModelOpen = (value) => {
        console.log("value ", value);

        setEditModalState({ ...editModalState, admin_id:value.admin_id, full_name: value.full_name, email: value.email, phone: value.phone, admin_type: value.admin_type , password:value.password , shipments:value.previlage.shipments!=undefined?value.previlage.shipments:false,customers:value.previlage.customers!=undefined?value.previlage.customers:false,master_settings:value.previlage.master_settings!=undefined?value.previlage.master_settings:false,system_settings:value.previlage.system_settings!=undefined?value.previlage.system_settings:false,payments:value.previlage.payments!=undefined?value.previlage.payments:false,users:value.previlage.users!=undefined?value.previlage.users:false,web_management:value.previlage.web_management!=undefined?value.previlage.web_management:false, create_shipment:value.previlage.create_shipment!=undefined?value.previlage.create_shipment:false, show: true  });
    
    }

  

    const handlePageChange = (event, newPage) => {
        setOtherState({ ...otherStates, page: newPage })
        //console.log("newPage ", newPage);
        getUsersList(newPage);
    }
    const handleChangeRowsPerPage = (event) => {
        console.log("event ", event);
    }
    // const searchUser = (e) =>{
    //      setOtherState({...otherStates,searchKeyword:e.target.value})
    //     getUsersList(0,e.target.value,true);
    //  }
    // const modalClose = (value) => {
    //     setModalState({ show: false });
    //     console.log("value ",value);
    // }
    function deleteship(user_id, dstatus) {
        let userdata = { user_id: user_id, dstatus: dstatus }
        axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user_remove`, userdata, { headers: exportValue.headers }).then((res) => {

            setState({ ...state, user_id: user_id });
            getUsersList(0, true);
            //  setModals({...modals, show:false})
            console.log("res", res)

            if (res.data.status == 200) {
                Swal.fire(
                    'Good job!',
                    'USer is Deleted Successfully !',
                    'success'
                )
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',

                })
            }
        }).catch((e) => {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',

            })

        })
    }
    function suspend(user_id, dstatus) {
        let userdata = { user_id: user_id, dstatus: dstatus }
        axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user_remove`, userdata, { headers: exportValue.headers }).then((res) => {

            setState({ ...state, user_id: user_id });
            getUsersList(0, true);
            //  setModals({...modals, show:false})
            console.log("res", res)

            if (res.data.status == 200) {
                Swal.fire(
                    'Good job!',
                    'User is Suspended Successfully !',
                    'success'
                )
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',

                })
            }
        }).catch((e) => {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',

            })

        })
    }
    function activeInactive(user_id, dstatus) {
        let userdata = { user_id: user_id, dstatus: dstatus }
        axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user_remove`, userdata, { headers: exportValue.headers }).then((res) => {

            setState({ ...state, user_id: user_id });
            getUsersList(0, true);
            //  setModals({...modals, show:false})
            console.log("res", res)

            if (res.data.status == 200) {
                Swal.fire(
                    'Good job!',
                    'User status changed Successfully !',
                    'success'
                )
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',

                })
            }
        }).catch((e) => {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',

            })

        })
    }
   
  
  
   
   

    const stateHandler = (e) => {
        // state[key] = value
        if (e.target.name == "searchtitle") {

           // params.delete('search')
            //search = null;
            setsearchFilters({ ...searchfilters, searchtitle: e.target.value })
            //   navigate({ search: params.toString() })

        }
        //    setsearchFilters({ ...searchfilters, searchtitle:searchfilters.searchtitle})
       // setOtherState({ ...otherStates, onload: true })
    }
   
    React.useEffect(() => {
        if (search) {
            setsearchFilters({ ...searchfilters, searchtitle: search })
        }
        // searchUser(0)
        // axios_get_api(null,0,true);


    }, [])

   
    const inputHandleChange = (e) => {
        // console.log(e.target.name);
        // console.log(e.target.value);
        if (e.target.type == "file") {
            setNewModalState({ ...newtModalState, [e.target.name]: e.target.files });
        }else if(e.target.type=="checkbox"){
            if (e.target.checked == true) {
                setNewModalState({...newtModalState , [e.target.name]:true })
        } 
        else{
            setNewModalState({...newtModalState , [e.target.name]:false })
        }
    }
        else {
            setNewModalState({ ...newtModalState, [e.target.name]: e.target.value });
        }
    }
    const inputeditHandleChange = (e) => {
        console.log(e.target.name);
        console.log(e.target.checked);
        if (e.target.type == "file") {
            setEditModalState({ ...editModalState, [e.target.name]: e.target.files });
        }else if(e.target.type=="checkbox"){
            if (e.target.checked == true) {
                setEditModalState({...editModalState , [e.target.name]:true })
        }
        else{
            setEditModalState({...editModalState , [e.target.name]:false })
        } 
    }
        else {
            setEditModalState({ ...editModalState, [e.target.name]: e.target.value });
        }
    }

   

    const newUserSave = () => {
        console.log(newtModalState)
        if (newtModalState.full_name != "" && newtModalState.email != "" && newtModalState.phone != "" && newtModalState.admin_type != "") {
            setIsLoading(true)
            let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/new_user`;
            
            let bodyData = newtModalState;
            console.log("bb", bodyData);
            axios.post(full_api, bodyData, {
                headers: exportValue.headers
            }).then((res) => {
                 setIsLoading(false)
                console.log("res ", res);
                if (res.data.status == "success") {
                    // setEditModalState({ show: false });
                    setNewModalState({ full_name: "", email: "", phone: "", admin_type: "",shipments:false,customers:false,master_settings:false,system_settings:false,payments:false,users:false,web_management:false,create_shipment:false ,show: false })

                   
                        getUsersList(0, true)
                    
                        Toast.fire({
                            background: "red",
                            type: 'success',
                            title: "Added Successfully !",
                            color: "white"
                        });
                   
                } else {
                    Toast.fire({
                        background: "#e63900",
                        type: 'error',
                        title: res.data.message,
                        color: "white"
                    });
                }
                // navigate('/categories')
                // let newCategory = res.data.category;
                //setState(state.concat([newCategory]))
            }).catch((e) => {
                setIsLoading(false)
                Toast.fire({
                    background: "#e63900",
                    type: 'error',
                    title: "Something Went Wrong",
                    color: "white"
                });
            });

        } else {
            Toast.fire({
                background: "#e63900",
                type: 'error',
                title: "Please fill all data.",
                color: "white"
            });
        }
    }

    const newUserEdit = () => {
        console.log(editModalState)
        if (editModalState.full_name != "" && editModalState.email != "" && editModalState.phone != "" && editModalState.admin_type != "" && editModalState.password!="") {
            setIsLoading(true)
            let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/new_user_edit`;
         
            let bodyData = editModalState;
            console.log("bb", bodyData);
            axios.post(full_api, bodyData, {
                headers: exportValue.headers
            }).then((res) => {
                 setIsLoading(false)
                console.log("res ", res);
                if (res.data.status == "success") {
                     setEditModalState({ show: false });
                     getUsersList(0, true)
                 
                        Toast.fire({
                            background: "red",
                            type: 'success',
                            title: "Updated Successfully !",
                            color: "white"
                        });
                   
                } else {
                    Toast.fire({
                        background: "#e63900",
                        type: 'error',
                        title: res.data.message,
                        color: "white"
                    });
                }
                // navigate('/categories')
                // let newCategory = res.data.category;
                //setState(state.concat([newCategory]))
            }).catch((e) => {
                setIsLoading(false)
                Toast.fire({
                    background: "#e63900",
                    type: 'error',
                    title: "Something Went Wrong",
                    color: "white"
                });
            });

        } else {
            Toast.fire({
                background: "#e63900",
                type: 'error',
                title: "Please fill all data.",
                color: "white"
            });
        }
    }


    return (
        <div>
            <Modal show={newtModalState.show} onHide={() => setNewModalState({ full_name: "", email: "", phone: "", admin_type: "", show: false })}>
                <Modal.Header closeButton>
                    <Modal.Title>New User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <label for="email">Full name</label>
                            <input className="form-control" id="first_name" type="text" placeholder="Enter name" name="full_name" onChange={(e) => inputHandleChange(e)} required />
                            <span>*Please fill </span>
                        </div>
                        <div className="col-md-12 mb-3">
                            <label for="email">Email</label>
                            <input className="form-control" id="first_name" type="email" placeholder="Enter Email" name="email" onChange={(e) => inputHandleChange(e)} required  autoComplete="off"/>

                        </div>
                        <div className="col-md-12 mb-3">
                            <label for="email">Password</label>
                            <input className="form-control" id="first_name" type="password" placeholder="Enter Email" name="password" onChange={(e) => inputHandleChange(e)} required autoComplete="off"/>

                        </div>
                        <div className="col-md-12 mb-3">
                            <label for="email">Phone</label>
                            <input className="form-control" id="first_name" type="Text" name="phone" placeholder="Enter phone" onChange={(e) => inputHandleChange(e)} />
                        </div>
                        <div className="col-md-12 mb-3">
                            <label for="email">Type</label>
                            <select className="form-control" name="admin_type" onChange={(e) => inputHandleChange(e)}>
                                <option value="">Select </option>
                                <option value="3">Staff</option>
                                <option value="2">Admin</option>
                            </select>
                            {/* <input className="form-control" id="first_name" type="file" name="flag"  /> */}
                        </div>
                        <div className="col-md-12 mb-3">
                            <label for="email" className="mb-2">Set Privilege</label>
                            <div className="row mb-2">
                            <div className="col-md-6">
                            <small for="email">Shipments</small> 
                             <input class="form-check-input ms-2" type="checkbox" name="shipments" id="inlineRadio1" value="option1" onChange={(e) => inputHandleChange(e)} checked={newtModalState.shipments==true}/>
                             </div>
                             <div className="col-md-6">
                            <small for="email">Customers</small> 
                             <input class="form-check-input ms-2" type="checkbox" name="customers" id="inlineRadio1" value="option1" onChange={(e) => inputHandleChange(e)} checked={newtModalState.customers==true}/>
                             </div>
                            </div>
                            <div className="row mb-2">
                           
                             <div className="col-md-6">
                            <small for="email">System Settings</small> 
                             <input class="form-check-input ms-2" type="checkbox" name="system_settings" id="inlineRadio1" value="option1" onChange={(e) => inputHandleChange(e)} checked={newtModalState.system_settings==true}/>
                             </div>
                             <div className="col-md-6">
                            <small for="email">Master Settings</small> 
                             <input class="form-check-input ms-2" type="checkbox" name="master_settings" id="inlineRadio1" value="option1" onChange={(e) => inputHandleChange(e)} checked={newtModalState.master_settings==true}/>
                             </div>
                            </div>
                            <div className="row mb-2">
                            <div className="col-md-6">
                            <small for="email">Users</small> 
                             <input class="form-check-input ms-2" type="checkbox" name="users" id="inlineRadio1" value="option1" onChange={(e) => inputHandleChange(e)} checked={newtModalState.users==true}/>
                             </div>
                             <div className="col-md-6">
                            <small for="email">Web Management</small> 
                             <input class="form-check-input ms-2" type="checkbox" name="web_management" id="inlineRadio1" value="option1" onChange={(e) => inputHandleChange(e)} checked={newtModalState.web_management==true} />
                             </div>
                            </div>
                            <div className="row">
                            <div className="col-md-4">
                            <small for="email">Payments</small> 
                             <input class="form-check-input ms-2" type="checkbox" name="payments" id="inlineRadio1" value="option1" onChange={(e) => inputHandleChange(e)} checked={newtModalState.payments==true}/>
                             </div>
                             <div className="col-md-4">
                            <small for="email">Create Shipments</small> 
                             <input class="form-check-input ms-2" type="checkbox" name="create_shipment" id="inlineRadio1" value="option1" onChange={(e) => inputHandleChange(e)} checked={newtModalState.create_shipment==true}/>
                             </div>
                            </div>
                         
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setNewModalState({ full_name: "", email: "", phone: "", admin_type: "", show: false })}>
                        Cancel
                    </Button>
                    <Button variant="primary"
                        onClick={() =>
                            newUserSave()
                        }>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={editModalState.show} onHide={() => setEditModalState({ show: false })}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit State</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="row">
                        <div className="col-md-12 mb-3">
                            <label for="email">Full name</label>
                            <input className="form-control" id="first_name" type="text" placeholder="Enter name" name="full_name" onChange={(e) => inputeditHandleChange(e)} required value={editModalState.full_name} />
                            <span>*Please fill </span>
                        </div>
                        <div className="col-md-12 mb-3">
                            <label for="email">Email</label>
                            <input className="form-control" id="first_name" type="email" placeholder="Enter Email" name="email" onChange={(e) => inputeditHandleChange(e)} required  autoComplete="off" value={editModalState.email}/>

                        </div>
                        <div className="col-md-12 mb-3">
                            <label for="email">Password</label>
                            <input className="form-control" id="first_name" type="password" placeholder="Enter password" name="password" onChange={(e) => inputeditHandleChange(e)} required autoComplete="off" />

                        </div>
                        <div className="col-md-12 mb-3">
                            <label for="email">Phone</label>
                            <input className="form-control" id="first_name" type="Text" name="phone" placeholder="Enter phone" onChange={(e) => inputeditHandleChange(e)} value={editModalState.phone}/>
                        </div>
                        {/* <div className="col-md-12 mb-3">
                            <label for="email">Type</label>
                            <input className="form-control" name="admin_type" onChange={(e) => inputeditHandleChange(e)} value={editModalState.admin_type==2?"Admin":"Staff"} disabled/>
                                {/* <option value={editModalState.admin_type==2?"2":"3"}>{editModalState.admin_type==2?"Admin":"Staff"} </option>
                                <option value={editModalState.admin_type==2?"3":"2"}>{editModalState.admin_type==2?"Staff":"Admin"}</option> */}
                                {/* <option >{editModalState.admin_type==2?"Admin":"Staff"}</option>
                            {editModalState.admin_type==2?<option value="3">Staff</option>:<option value="2">Admin</option>}                                
                            </select> */}
                            {/* <input className="form-control" id="first_name" type="file" name="flag"  /> */}
                        {/* </div>  */}
                        <div className="col-md-12 mb-3">
                            <label for="email" className="mb-2">Set Privilege</label>
                            <div className="row mb-2">
                            <div className="col-md-6">
                            <small for="email">Shipments</small> 
                             <input class="form-check-input ms-2" type="checkbox" name="shipments" id="inlineRadio1" value="option1" onChange={(e) => inputeditHandleChange(e)} checked={editModalState.shipments==true}/>
                             </div>
                             <div className="col-md-6">
                            <small for="email">Customers</small> 
                             <input class="form-check-input ms-2" type="checkbox" name="customers" id="inlineRadio1" value="option1" onChange={(e) => inputeditHandleChange(e)} checked={editModalState.customers==true}/>
                             </div>
                            </div>
                            <div className="row mb-2">
                           
                             <div className="col-md-6">
                            <small for="email">System Settings</small> 
                             <input class="form-check-input ms-2" type="checkbox" name="system_settings" id="inlineRadio1" value="option1" onChange={(e) => inputeditHandleChange(e)} checked={editModalState.system_settings==true}/>
                             </div>
                             <div className="col-md-6">
                            <small for="email">Master Settings</small> 
                             <input class="form-check-input ms-2" type="checkbox" name="master_settings" id="inlineRadio1" value="option1" onChange={(e) => inputeditHandleChange(e)} checked={editModalState.master_settings==true}/>
                             </div>
                            </div>
                            <div className="row mb-2">
                            <div className="col-md-6">
                            <small for="email">Users</small> 
                             <input class="form-check-input ms-2" type="checkbox" name="users" id="inlineRadio1" value="option1" onChange={(e) => inputeditHandleChange(e)} checked={editModalState.users==true}/>
                             </div>
                             <div className="col-md-6">
                            <small for="email">Web Management</small> 
                             <input class="form-check-input ms-2" type="checkbox" name="web_management" id="inlineRadio1" value="option1" onChange={(e) => inputeditHandleChange(e)} checked={editModalState.web_management==true} />
                             </div>
                            </div>
                            <div className="row">
                            <div className="col-md-4">
                            <small for="email">Payments</small> 
                             <input class="form-check-input ms-2" type="checkbox" name="payments" id="inlineRadio1" value="option1" onChange={(e) => inputeditHandleChange(e)} checked={editModalState.payments==true}/>
                             </div>
                             <div className="col-md-4">
                            <small for="email">Create Shipment</small> 
                             <input class="form-check-input ms-2" type="checkbox" name="create_shipment" id="inlineRadio1" value="option1" onChange={(e) => inputeditHandleChange(e)} checked={editModalState.create_shipment==true}/>
                             </div>
                            </div>
                         
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setEditModalState({ show: false })}>
                        Cancel
                    </Button>
                    <Button variant="primary"
                        onClick={() =>
                            newUserEdit()
                        }>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>

          
            {(isLoading) ?
                <div style={{ backgroundColor: "#808080", zIndex: 2, height: "100%", width: "100%", position: "absolute", opacity: "0.5" }}>
                    <div style={{ left: "47%", top: "45%", position: "absolute" }}>
                        <ReactLoading type={"spin"} color={"#000000"} height={300} width={89} />
                    </div>
                </div> : ""}
                <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
       <section class="home-section">
               {/* <?php
               include('sub_header.php');
               ?> 
            */}
       <Sub_header/>
               
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                        <div className="d-block mb-4 mb-md-0">
                            {/* <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                                <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                    <li className="breadcrumb-item">
                                        <a href="#">
                                            <svg className="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Users</li>
                                </ol>
                            </nav> */}
                            <h2 className="h4">All Staff</h2>
                        </div>
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <div className="input-group me-2 me-lg-3 fmxw-400">
                                <span className="input-group-text"  onClick={() => getUsersList(0, true)}>
                                    <svg className="icon icon-xs" x-description="Heroicon name: solid/search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                                    </svg>
                                </span>
                                <input type="text" className="form-control" placeholder="Search staff" name="searchtitle" onChange={(e) => stateHandler(e)}/>
                            </div>
                            <a className="btn btn-sm btn-gray-800 d-inline-flex align-items-center" onClick={() => setNewModalState({ show: true })}>
                                <svg className="icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                                Add New Staff
                            </a>
                        </div>
                    </div>

                    <section>
                        <ul className="nav nav-tabs justify-content-end">
                            <li className="nav-item">
                                <a className="nav-link active" href="#">All</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Active</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Inactive</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Deleted</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Suspeded</a>
                            </li>
                        </ul>
                    </section>
                    <div className="card card-body border-0 shadow table-wrapper table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th className="border-gray-200">#</th>
                                    <th className="border-gray-200">Date</th>
                                    <th className="border-gray-200">Name</th>
                                    <th className="border-gray-200">Email</th>
                                    <th className="border-gray-200">Mobile #</th>
                                    <th className="border-gray-200">Type</th>
                                    <th className="border-gray-200">Status</th>
                                    <th className="border-gray-200">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* <!-- Item --> */}
                                {state.user_list.map((user, index) =>
                                    <tr>
                                        <td>
                                            {index + 1}
                                        </td>
                                        <td>
                                            <span className="fw-normal">{user.date} <small>{user.time}</small></span>
                                        </td>
                                        <td>
                                            <a onClick={()=>editModelOpen(user)} className="fw-bold text-info">
                                                {user.full_name}
                                            </a>
                                        </td>
                                        <td><span className="fw-normal">{user.email}</span></td>
                                        <td><span className="fw-bold">{user.phone}</span></td>
                                        <td><span className="badge rounded-pill bg-info">{(user.admin_type == 3) ? "Staff" : (user.admin_type == 2)? "Admin":""}</span></td>
                                        <td><span className={"badge rounded-pill user " + (user.dstatus == 1 ? 'bg-success' : '')}>{(user.dstatus == 1) ? "Active" : ""}</span></td>
                                        <td>
                                            <div className="btn-group">
                                                <button className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span className="icon icon-sm">
                                                        <span className="fa fa-ellipsis-h icon-dark"></span>
                                                    </span>
                                                    <span className="visually-hidden">Toggle Dropdown</span>
                                                </button>
                                                <div className="dropdown-menu py-0">
                                                    <a className="dropdown-item rounded-top" onClick={()=>editModelOpen(user)}><span className="fa fa-eye me-2"></span>View/Update</a>
                                                    {/* <a className="dropdown-item rounded-top" href="#"><span className="fa fa-eye-slash me-2"></span>Inactive User</a>
                                                    <a className="dropdown-item text-danger rounded-top" href="#"><span className="fa fa-ban me-2"></span>Suspend User</a>
                                                    <a className="dropdown-item text-danger rounded-bottom" href="#"><span className="fa  fa-trash me-2"></span>Remove</a> */}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                                {/* <tr>
                            <td>
                                01
                            </td>
                            <td>
                                <span className="fw-normal">12/08/2022 <small>12:23</small></span>
                            </td>
                            <td>
                                <a href="user_info.php" className="fw-bold text-info">
                                    Mukesh Kumar 
                                </a>
                            </td>                        
                            <td><span className="fw-normal">mukesh@gmail.com</span></td>
                            <td><span className="fw-bold">+919239238233</span></td>
                            <td><span className="badge rounded-pill bg-info">Staff</span></td>
                            <td><span className="badge rounded-pill bg-success">Active</span></td>
                            <td>
                                <div className="btn-group">
                                    <button className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span className="icon icon-sm">
                                            <span className="fa fa-ellipsis-h icon-dark"></span>
                                        </span>
                                        <span className="visually-hidden">Toggle Dropdown</span>
                                    </button>
                                    <div className="dropdown-menu py-0">
                                        <a className="dropdown-item rounded-top" href="#"><span className="fa fa-eye me-2"></span>View/Update</a>
                                        <a className="dropdown-item rounded-top" href="#"><span className="fa fa-eye-slash me-2"></span>Inactive User</a>
                                        <a className="dropdown-item text-danger rounded-top" href="#"><span className="fa fa-ban me-2"></span>Suspend User</a>
                                        <a className="dropdown-item text-danger rounded-bottom" href="#"><span className="fa  fa-trash me-2"></span>Remove</a>
                                    </div>
                                </div>
                            </td>
                        </tr> */}

                            </tbody>
                        </table>

                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[500,800,1000]}
                            count={otherStates.total_count}
                            page={otherStates.page}
                            onPageChange={handlePageChange}
                            rowsPerPage={otherStates.rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}

                        />
                        {/* 
                    <div className="card-footer px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                    <nav aria-label="Page navigation example">
                        <ul className="pagination mb-0">
                            <li className="page-item">
                                <a className="page-link" href="#">Previous</a>
                            </li>
                            <li className="page-item">
                                <a className="page-link" href="#">1</a>
                            </li>
                            <li className="page-item active">
                                <a className="page-link" href="#">2</a>
                            </li>
                            <li className="page-item">
                                <a className="page-link" href="#">3</a>
                            </li>
                            <li className="page-item">
                                <a className="page-link" href="#">4</a>
                            </li>
                            <li className="page-item">
                                <a className="page-link" href="#">5</a>
                            </li>
                            <li className="page-item">
                                <a className="page-link" href="#">Next</a>
                            </li>
                        </ul>
                    </nav>
                    <div className="fw-normal small mt-4 mt-lg-0">Showing <b>5</b> out of <b>25</b> entries</div> 
                     </div>
                    */}

                    </div>


                </section>
          
        </div>
    )
}

export default Users