import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel';
import Sub_header from '../Sub_header';
import '../../components/loader.css';
import { useLocation, useNavigate } from 'react-router-dom'
import TablePagination from '@mui/material/TablePagination';
import queryString from 'query-string';

const Servicable_pincodes = () => {
   let navigate = useNavigate();
    const search1 = window.location.search;
    const params = new URLSearchParams(search1);
    let search= params.get('search')
   
    const [otherStates,setOtherState] = React.useState({activePage:1,rowsPerPage:500,page:0,total_count:0,onload:true});
    const [carrierList, setcarrierList] = useState([]);
     const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

     const toggleSidebar = () => {
         setIsSidebarOpen(!isSidebarOpen);
         const homeSection = document.querySelector('.home-section');
         if (homeSection) {
             homeSection.classList.toggle('sidebar-closed');
         }
     };
    useEffect(() => {
        getCarrierList();
    }, [])

    const getCarrierList = (index=0) => {


        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/serviceable_carrier_list`;
        let sendData = {  dstatus:1,
            indexValue:index,
            limit:otherStates.rowsPerPage};
       
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res ", res);
            if(index==0  && otherStates.onload) {
                setOtherState({...otherStates,total_count:res.data.dataCount})         
    }
            setcarrierList(res.data.output)


        }).catch((e) => {


        });
    }
     
 

      const handlePageChange = (event,newPage) =>{
        console.log("newpage",newPage)
        setOtherState({...otherStates,page:newPage})
        //console.log("newPage ", newPage);
        getCarrierList(newPage);
        
        // searchUser(state.searchValue,newPage)
    }
    const handleChangeRowsPerPage = (event,newPage) => {
        console.log("event ", event);
        setOtherState({...otherStates, rowsPerPage:+event.target.value, page:0})
       
    }

   
    
  
    
  return (
    <div>
        <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
       <section class="home-section">
               {/* <?php
               include('sub_header.php');
               ?> 
            */}
       <Sub_header/>
      
      

<div class="row py-4">
    <div class="col-12 col-md">
      
        <h2 class="h4">All Carriers Serviceable Pincodes List</h2>
    </div>
    <div class="col-12 col-md-5">
        
    </div>
   
</div>



        
        
        <div class="card card-body border-0 shadow table-wrapper table-responsive">
            <table class="table table-hover carr_list_ad">
                <thead>
                    <tr>
                        <th class="border-gray-200">#</th>
                        <th class="border-gray-200">#ID</th>	
                        <th class="border-gray-200">Carrier Name</th>
                       
                    </tr>
                </thead>
                {carrierList.map((sub,index)=>(
                <tbody>
                    {/* <!-- Item --> */}
                    <tr>
                        <td>
                            {index+1} 
                        </td>
                        <td>
                            <span class="fw-normal"><a   onClick={()=> navigate({
                            pathname: `/servicable_pincodes/${sub.carrier_id}`,
                            search: `carrier_name=${sub.carrier_name}`})}>{sub.carrier_id}</a></span>
                        </td>                        
                      
                        <td>
                            <strong>{sub.carrier_name}</strong>
                        </td>                        
                        <td class="">
                        <img src={`https://cdn.connectingmiles.co.in/images/${sub.carrier_image}`} style={{width:"60px"}} className="mt-3"/>
                       <br/> <small className="text-danger">{sub.carrier_name}</small> 
                        </td>
                        <td><a className="btn btn-primary" href={`/servicable_pincode_import_process/${sub.carrier_id}`}>Log</a></td>
                      
                    </tr>
                                                
                </tbody>
                ))}
            </table>
            <TablePagination
                component="div"
                rowsPerPageOptions={[500,800,1000]}
                count={otherStates.total_count}
                page={otherStates.page}
                onPageChange={handlePageChange}
                rowsPerPage={otherStates.rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
               
                        />
        </div> 
                

</section>




    </div>
  )
}

export default Servicable_pincodes
