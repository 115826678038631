import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel';
import Sub_header from '../Sub_header';
import '../../components/loader.css';
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import TablePagination from '@mui/material/TablePagination';

const Products = () => {
    let navigate = useNavigate()
    let {customer_id} = useParams();
    const [productList, setproductList] = useState([]);
    const [otherStates,setOtherState] = React.useState({activePage:1,rowsPerPage:500,page:0,total_count:0,onload:true});
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
    React.useEffect(()=>{
        getProductList()
    },[])

    const getProductList = (index=0) => {


        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/carrier_product_list_api`;
        let sendData = {  dstatus:1,
            indexValue:index,
            limit:otherStates.rowsPerPage};
       
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res ", res);
            if(index==0  && otherStates.onload) {
                setOtherState({...otherStates,total_count:res.data.dataCount})         
    }
         setproductList(res.data.output)


        }).catch((e) => {


        });
    }

    const handlePageChange = (event,newPage) =>{
        console.log("newpage",newPage)
        setOtherState({...otherStates,page:newPage})
        //console.log("newPage ", newPage);
        getProductList(newPage);
        
        // searchUser(state.searchValue,newPage)
    }
    const handleChangeRowsPerPage = (event,newPage) => {
        console.log("event ", event);
        setOtherState({...otherStates, rowsPerPage:+event.target.value, page:0})
       
    }

  return (
    <div>
     <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
       <section class="home-section">
               {/* <?php
               include('sub_header.php');
               ?> 
            */}
       <Sub_header/>
   


<div class="row py-4">
<div class="col-12 col-md">
   <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
       <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
           <li class="breadcrumb-item">
               <a href="#">
                   <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
               </a>
           </li>
           <li class="breadcrumb-item active" aria-current="page">Product List</li>
       </ol>
   </nav>
   <h2 class="h4">All Product</h2>
</div>
</div>

   <section>
       <ul class="nav nav-tabs justify-content-end">
           <li class="nav-item">
           <a class="nav-link active" href="#">All</a>
           </li>
           <li class="nav-item">
           <a class="nav-link" href="#">Domestic</a>
           </li>
           <li class="nav-item">
           <a class="nav-link" href="#">International</a>
           </li>
       </ul>
   </section>
   
   <div class="card card-body border-0 shadow table-wrapper table-responsive">
       <table class="table table-hover carr_list_ad">
           <thead>
               <tr>
                   {/* <th class="border-gray-200">#</th> */}
                   <th class="border-gray-200">#ID</th>	
                   <th class="border-gray-200">Product Name</th>
                   <th class="border-gray-200">Carrier Name</th>
                   <th class="border-gray-200">Max Weight Cap.</th>
                   <th class="border-gray-200">Mode</th>
                   {/* <th class="border-gray-200">Status </th> */}
                   {/* <th class="border-gray-200">Action</th> */}
               </tr>
           </thead>
           <tbody>
               {productList.map((sub,index)=>(
               
               <tr>
                   {/* <td>
                       {index+1}
                   </td> */}
                   <td>
                       <span class="fw-normal"><a href={`/product_detail/${customer_id}/${sub.product_id}/${sub.carrier_id}`}>{sub.product_id}</a></span>
                   </td>                        
                   <td>
                       <strong>{sub.product_name} </strong>
                   </td>                         
                   <td>
                       <strong><img src={`https://cdn.connectingmiles.co.in/images/${sub.carrier_image}`} width="100px" /> </strong>
                       <br/>
                       <small className="text-danger">{sub.carrier_name}</small>
                       
                   </td>                        
                   <td class="">
                       {sub.max_weight}KG
                   </td>
                   <td class="">
                       <span class="badge rounded-pill bg-primary">{sub.mode}</span>
                   </td>
                   {/* <td>
                       <div class="form-check form-switch  mb-2">
                          <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={sub.status==1}/>
                       </div>
                   </td> */}
                   {/* <td>
                       <div class="btn-group">
                           <button class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                               <span class="icon icon-sm">
                                   <span class="fa fa-ellipsis-h icon-dark"></span>
                               </span>
                               <span class="visually-hidden">Toggle Dropdown</span>
                           </button>
                           <div class="dropdown-menu py-0">
                               <a class="dropdown-item rounded-top" href={`/edit_product/${sub.product_id}/${sub.carrier_id}`}><span class="fa fa-eye me-2" ></span>View/Update</a>
                               <a class="dropdown-item text-danger rounded-bottom" href="#"><span class="fa  fa-trash me-2"></span>Remove</a>
                           </div>
                       </div>
                   </td> */}
               </tr>
               ))}
                        
           </tbody>
       </table>
       <TablePagination
           component="div"
           rowsPerPageOptions={[500,800,1000]}
           count={otherStates.total_count}
           page={otherStates.page}
           onPageChange={handlePageChange}
           rowsPerPage={otherStates.rowsPerPage}
           onRowsPerPageChange={handleChangeRowsPerPage}
          
                   />
   </div> 
           

</section>

</div>
)
}


export default Products
