import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel';
import Sub_header from '../Sub_header';
import '../../components/loader.css';
import { useNavigate, useParams } from 'react-router-dom'
import TablePagination from '@mui/material/TablePagination';

const Weight_discrepancies_log = () => {
    let record_id = useParams();
    let navigate = useNavigate();
    const search1 = window.location.search;
    const params = new URLSearchParams(search1);
    let search= params.get('search')
    const [otherStates,setOtherState] = React.useState({activePage:1,rowsPerPage:10,page:0,total_count:0,onload:true});
    const [carrierList, setcarrierList] = useState([]);
    console.log("carrierList",carrierList)
    const[editmodals,setEditModals] = React.useState({show: false, carrier_id:"" ,carrier_name:"",carrier_image:"",})
    const[addImg,setAddImg]=React.useState({uploadPhoto:""})
    const [stateSave, setSaveState] = React.useState({ uploadimg: ""});
     const[statusState, setstatusState] = React.useState({status:1})
    const [uploadModalState, setUploadModalState] = useState({ show: false,excel_fle:"" });
    const [selectCarrier, setSelectCarrier] = useState({ carrier_id:"" });


 
  
     const [isImageSelected, setIsImageSelected] = React.useState(false);
     const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    const homeSection = document.querySelector('.home-section');
    if (homeSection) {
        homeSection.classList.toggle('sidebar-closed');
    }
};
  
    useEffect(() => {
        getCarrierList();
    }, [])

    const getCarrierList = (index=0) => {


        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/weight_discrepancies_file_log`;
        let sendData = {  record_id:record_id};
       
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res ", res);
            if(index==0  && otherStates.onload) {
                // setOtherState({...otherStates,total_count:res.data.dataCount})         
    }
            setcarrierList(res.data.output.log)


        }).catch((e) => {


        });
    }
    const handleRefreshButtonClick = () => {
        window.location.reload(); // Reloads the page
      };
 
  return (
    <div>
        <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
       <section class="home-section">
               {/* <?php
               include('sub_header.php');
               ?> 
            */}
       <Sub_header/>
<div class="row py-4">
    <div class="col-12 col-md">
        <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
             
                {/* <li class="breadcrumb-item active" aria-current="page"></li> */}
            </ol>
        </nav>
        <h2 class="h4">Weight Discrepancies Log {record_id}</h2>
    </div>
    <div class="col-12 col-md-5">
        
    </div>
</div>

<section>

                                 {/* edit modal */}
                                  

</section>

        
        
        <div class="card card-body border-0 shadow table-wrapper table-responsive">
            <table class="table table-hover carr_list_ad">
                <thead>
                    <tr>
                        <th class="border-gray-200">#</th>
                        <th class="border-gray-200">#AWB NUMBER</th>	
                        <th class="border-gray-200">REASON</th>
                        <th class="border-gray-200">STATUS</th>
                     
                    </tr>
                </thead>
                {carrierList.length>0?
                carrierList.map((sub,index)=>(
                <tbody>
                 
                    <tr>
                        <td>
                            {index+1}
                        </td>
                        <td>
                            <span class="fw-normal"><a href="">{sub.tracking_number}</a></span>
                        </td>                        
                        <td>
                            <strong>{sub.status==1?"Success":"Failed"}</strong>
                        </td>                        
                      
                        <td>
                          {sub.error}
                        </td>
                      
                    </tr>
                                                
                </tbody>
                )):<>
       
        
                <tr >
                 <td colSpan="3" style={{ textAlign: 'center' }} >
                   {/* Centered content */}
                   <div className='mt-5'>
                     <h6>Your File is Still In Processing</h6>
                     <br />
                     <button class="btn btn-primary btn-block" onClick={() => handleRefreshButtonClick()}>Refresh</button>
                   </div>
                 </td>
               </tr>
                 
               
                 </>}
            </table>
            {/* <TablePagination
                component="div"
                rowsPerPageOptions={[5,10]}
                count={otherStates.total_count}
                page={otherStates.page}
                onPageChange={handlePageChange}
                rowsPerPage={otherStates.rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
               
                        /> */}
        </div> 
                

</section>




    </div>
  )
}

export default Weight_discrepancies_log
