import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel';
import Sub_header from '../Sub_header';
import '../../components/loader.css';
import { useNavigate, useParams } from 'react-router-dom'
import TablePagination from '@mui/material/TablePagination';

const Payout_import_log = () => {
    let payout_id = useParams();
    const [carrierList, setcarrierList] = useState([]);
    console.log("carrierList",carrierList)
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
   
    useEffect(() => {
        getCarrierList();
    }, [])

    const getCarrierList = () => {
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/upload_payout_file_list`;
        let sendData = {  payout_id:payout_id.payout_id};
       
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res ", res);
            setcarrierList(res.data.output)
           }).catch((e) => {
   });
    }
  
 
  return (
    <div>
         <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
       <section class="home-section">
               {/* <?php
               include('sub_header.php');
               ?> 
            */}
       <Sub_header/> 
       <section>

<div class="row py-4">
    <div class="col-12 col-md">
        <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
            </ol>
        </nav>
        <h2 class="h4">Payout Import Files</h2>
    </div>
    <div class="col-12 col-md-5">
        
    </div>
</div>

<section>

</section>
   <div class="card card-body border-0 shadow table-wrapper table-responsive">
            <table class="table table-hover carr_list_ad">
                <thead>
                    <tr>
                        <th class="border-gray-200">#</th>
                        <th class="border-gray-200">#FILE NAME</th>	
                        <th class="border-gray-200">UPLOADED RECORDS</th>
                        <th class="border-gray-200">TOTAL RECORDS</th>
                        <th class="border-gray-200">STATUS</th>
                     
                    </tr>
                </thead>
               
                {carrierList.map((sub,index)=>(
                <tbody>
                 
                    <tr>
                        <td>
                            {index+1}
                        </td>
                        <td>
                            <span class="fw-normal">{sub.file_name}</span>
                        </td>  
                        <td>{sub.uploaded_record}</td>   
                        <td>{sub.total_record}</td>                      
                        <td>
                            <strong>{sub.current_status==1?"Completed":"Pending"}</strong>
                        </td>    
                        <td>
                      <a href={`/payout_log_detail/${payout_id.payout_id}/${sub.record_id}`}>Log</a>
                        </td>                    
                      
                    </tr>
                                                
                </tbody>
                ))}
            </table>
           
        </div> 
                
</section>
</section>

</div>


   
  )
}

export default Payout_import_log
